import React from "react";
import {Route, Routes} from "react-router-dom";
import Header from './components/Header/Header';
import "./js/script";
import Services from './components/Services/Services';
import About from './Pages/About/About';
import Qualities from './components/Qualities/Qualities';
import Features from './components/Features/Features';
import Portfolio from './components/Portfolio/Portfolio';
import Testimonials from './components/Testimonials/Testimonials';
import Contact from './Pages/Contact/Contact';
import Footer from './components/Footer/Footer';
import Navbar from "./components/Navbar/Navbar";
import Home from "./Pages/Home/Home";
import Vacature from "./Pages/Vacature/Vacature";
import './App.css';


function App() {
  return (
      <div className="App">
          <Navbar/>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/OverOns" element={<About />} />
              <Route path="/Projecten" element={<Portfolio />} />
              <Route path="/Contact" element={<Contact />} />
              <Route path="/Vacature" element={<Vacature />} />
          </Routes>

          {/*<Header/>*/}
          {/*<Qualities/>*/}
          <Services/>
          {/*// Let op het lijkt dat je Feature component niet goed lijkt te werken als je Services component uitgeschakeld*/}
          <Features/>
          {/*<Testimonials/>*/}
          <Footer/>
      </div>
  );
}

export default App;
