import images from "./images";
import {
    FaPaperPlane,
    FaEdit,
    FaRocket,
    FaShoppingCart,
    FaFileAlt,
    FaPhoneAlt,
    FaEnvelopeOpen,
    FaMapMarkerAlt,
    FaHandshake
} from "react-icons/fa";
import {BiDollarCircle} from "react-icons/bi";
import {ImMagicWand} from "react-icons/im";
import {AiOutlineProject, AiOutlineReload, AiOutlineSafetyCertificate, AiOutlineSolution} from "react-icons/ai";
import {RiCustomerService2Fill} from "react-icons/ri";

const gradient = "url(#blue-gradient)" ;

const services = [
    {
        id: 1,
        icon: <AiOutlineSolution style = {{ fill: gradient }} />,
        title: "Expertise",
        text: "Met jarenlange ervaring in de branche beschikt ons team van professionals over de kennis en vaardigheden om geavanceerde glasvezel verbindingen te ontwerpen, implementeren en onderhouden."
    },
    {
        id: 2,
        icon: <ImMagicWand style = {{ fill: gradient }} />,
        title: "Aangepaste Oplossingen",
        text: "We begrijpen dat elke klant unieke behoeften heeft. Daarom bieden we op maat gemaakte oplossingen die zijn afgestemd op uw specifieke vereisten, zodat optimale prestaties en betrouwbaarheid worden gegarandeerd."
    },
    {
        id: 3,
        icon: <FaRocket style = {{ fill: gradient }} />,
        title: "Allesomvattende Diensten",
        text: "Van netwerkontwerp en installatie tot voortdurende onderhoud en ondersteuning, wij bieden een volledig scala aan diensten om aan al uw netwerkbehoeften te voldoen onder één dak."
    },
    {
        id: 4,
        icon: <FaEdit style = {{ fill: gradient }} />,
        title: "Kwaliteitsproducten",
        text: "Naast onze diensten bieden we ook een breed scala aan hoogwaardige netwerkproducten van toonaangevende merken, zodat u toegang heeft tot de beste apparatuur voor uw project."
    },
    {
        id: 5,
        icon: <RiCustomerService2Fill style = {{ fill: gradient }} />,
        title: "Klantenservice",
        text: "Bij Connect The World staat klanttevredenheid boven alles. Ons toegewijde team streeft ernaar om snelle, professionele en persoonlijke service te bieden, zodat uw ervaring met ons niets minder dan uitstekend is."
    },
    {
        id: 6,
        icon: <FaHandshake style = {{ fill: gradient }} />,
        title: "Samenwerking",
        text: "We kijken ernaar uit om met u samen te werken aan het creëren van snelle, betrouwbare en toekomstbestendige internetoplossingen die het leven voor u gemakkelijker maken."
    }
];

const about = [
    {
        id: 7,
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris finibus leo et diam fermentum ullamcorper. Nulla venenatis nibh sollicitudin tincidunt gravida. Nam convallis justo et ligula luctus suscipit. Etiam eu nisi turpis. Donec sollicitudin accumsan quam, rhoncus sagittis metus semper quis. Praesent convallis mauris sed ipsum lobortis facilisis. Nulla cursus sem non nunc sagittis, a volutpat mauris lobortis. Nulla ut feugiat tellus. Nam dictum nisi nec scelerisque auctor"
    }
]

const qualities = [
    {
        id: 8,
        icon: <AiOutlineProject style = {{ fill: gradient }} />,
        title: "Voor Klein & Groot",
        text: " Of u nu een klein bedrijf bent dat zijn online aanwezigheid wil verbeteren of een groot bedrijf dat behoefte heeft aan een robuuste netwerkstructuur."
    },
    {
        id: 9,
        icon: <AiOutlineSafetyCertificate style = {{ fill: gradient }}  />,
        title: "Met Kennis & Ervaring",
        text: "Wij hebben de kennis en ervaring om op maat gemaakte oplossingen te leveren die aan uw wensen voldoen."
    }
];

const features = [
    {
        id: 10,
        title: "1. Welke diensten biedt Connect The World aan?",
        text: "Bij Connect The World zijn we gespecialiseerd in het bouwen van snelle en betrouwbare internetoplossingen voor onze klanten. Onze diensten omvatten netwerkontwerp, installatie, onderhoud en ondersteuning. We bieden ook een breed scala aan netwerkproducten te koop aan (CTWShop.eu)."
    },
    {
        id: 11,
        title: "2. Welke soorten netwerken bouwt Connect The World?",
        text: "We hebben expertise in het aanleggen van verschillende soorten netwerken, waaronder bekabelde en draadloze netwerken, LAN's (Local Area Networks), FTTH (Fiber To The Home), FTTX, WAN's (Wide Area Networks) en WLAN's (Wireless Local Area Networks). Of u nu een kleinschalig netwerk voor uw huis of een grootschalig netwerk nodig heeft, wij kunnen u helpen."
    },
    {
        id: 12,
        title: "3. Hoe vraag ik een offerte aan voor diensten?",
        text: "Om een offerte voor onze diensten aan te vragen, vult u eenvoudig het contactformulier op onze website in of neemt u contact met ons op via e-mail info@connecttheworld.nl. Een van onze vertegenwoordigers zal snel contact met u opnemen om uw wensen te bespreken en u een op maat gemaakte offerte te verstrekken."
    },
    {
        id: 13,
        title: "4. Biedt Connect The World doorlopende ondersteuning voor geïnstalleerde netwerken?",
        text: "Ja, wij bieden doorlopende onderhouds- en ondersteuningsdiensten voor de netwerken die wij installeren. Ons team staat klaar om u te helpen met eventuele problemen of zorgen, zodat uw netwerk altijd soepel en efficiënt werkt."
    },
    {
        id: 14,
        title: "5. Kan ik netwerkproducten direct bij Connect The World kopen?",
        text: "Ja, wij bieden een breed scala aan hoogwaardige netwerkproducten te koop aan, waaronder routers, switches, kabels en meer. U kunt onze selectie online bekijken of direct contact met ons opnemen om te informeren naar specifieke producten."
    },
    {
        id: 15,
        title: "6. Hoe lang duurt het om een netwerkinstallatieproject te voltooien?",
        text: "De tijdlijn voor een netwerkinstallatieproject kan variëren afhankelijk van de omvang en complexiteit van het project. We zullen nauw met u samenwerken om een tijdlijn vast te stellen die aan uw behoeften voldoet en verstoringen tot een minimum beperkt."
    },
    {
        id: 16,
        title: "7. Is Connect The World gecertificeerd en verzekerd?",
        text: "Ja, we zijn volledig gecertificeerd en verzekerd om onze diensten te verlenen. Ons team bestaat uit ervaren professionals die zich houden aan de beste praktijken en veiligheidsnormen in de industrie om de hoogste kwaliteit van vakmanschap te waarborgen."
    },
    {
        id: 17,
        title: "8. Wat onderscheidt Connect The World van andere netwerkbedrijven?",
        text: "Bij Connect The World zijn we trots op onze expertise, professionaliteit en toewijding aan klanttevredenheid. We bieden op maat gemaakte oplossingen die zijn afgestemd op de unieke behoeften van elke klant, samen met uitzonderlijke klantenservice en ondersteuning."
    },
    {
        id: 18,
        title: "9. Verzendt u wereldwijd?",
        text: "Ja, we verzenden wereldwijd. Naar elke afgelegen locatie op de planeet."
    },
    {
        id: 19,
        title: "10. Welke betalingsmethoden accepteert u?",
        text: "We accepteren betalingen via Visa, Mastercard, Apple Pay, PayPal, en iDEAL."
    },
];

const portfolio = [
    {
        id: 20,
        title: "Lorem ipsum dolor sit amet, consectetur adipisicing elit",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elits",
        image: images.portfolio_img_1,
    },
    {
        id: 21,
        title: "Lorem ipsum dolor sit amet, consectetur adipisicing elit",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elits",
        image: images.portfolio_img_2,
    },
    {
        id: 22,
        title: "Lorem ipsum dolor sit amet, consectetur adipisicing elit",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elits",
        image: images.portfolio_img_3,
    }
];

const testimonials = [
    {
        id: 23,
        name: "Marie Jordan",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
        image: images.customer_img_1,
        rating: 3
    },
    {
        id: 24,
        name: "Ann Brown",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
        image: images.customer_img_2,
        rating: 5
    },
    {
        id: 25,
        name: "Andrew Bill",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
        image: images.customer_img_3,
        rating: 2
    },
    {
        id: 26,
        name: "Jason Stawer",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
        image: images.customer_img_4,
        rating: 4
    },
    {
        id: 27,
        name: "Lisa Green",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
        image: images.customer_img_5,
        rating: 2
    },
    {
        id: 28,
        name: "Anna Doe",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
        image: images.customer_img_6,
        rating: 4
    }
]

const contact = [
    {
        id: 29,
        icon: <FaPhoneAlt style = {{ fill: gradient }} />,
        info: "+425 235 712",
        text: "Lorem ipsum dolor sit mattis amet consectetur adipiscing"
    },
    {
        id: 30,
        icon: <FaEnvelopeOpen style = {{ fill: gradient }} />,
        info: "solnhub@info.com",
        text: "Lorem ipsum dolor sit mattis amet consectetur adipiscing"
    },
    {
        id: 31,
        icon: <FaMapMarkerAlt style = {{ fill: gradient }} />,
        info: "United Kingdom, New Street",
        text: "Lorem ipsum dolor sit mattis amet consectetur adipiscing"
    }
]

const sections = {services, about, qualities, features, portfolio, testimonials, contact};

export default sections;
