import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Logo from "../../assets/Logo.png";
import "./Navbar.css";
import {FaTwitter, FaSkype, FaVimeoV} from "react-icons/fa";
import {IoMdRocket} from "react-icons/io";

const Navbar = () => {

    const [navToggle, setNavToggle] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) { // Aanpassen aan de gewenste scrollpositie
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const navHandler = () => {
        setNavToggle(prevData => !prevData);
    }

  return (
      <nav className={`navbar w-100 flex ${isScrolled ? 'solid' : 'transparent'}`}>
      <div className='container w-100'>
            <div className='navbar-content flex fw-7'>
                <div className='brand-and-toggler flex flex-between w-100'>
                    <Link to= "/">
                    <img className='logo' src={Logo} alt="logo"/>
                    </Link>

                    {/*<Link to = "/" className='navbar-brand fs-26'>Connect The World</Link>*/}
                    <div type = "button" className={`hamburger-menu ${navToggle ? 'hamburger-menu-change' : ""}`} onClick={navHandler}>
                        <div className='bar-top'></div>
                        <div className='bar-middle'></div>
                        <div className='bar-bottom'></div>
                    </div>
                </div>

                <div className={`navbar-collapse ${navToggle ? 'show-navbar-collapse' : ""}`}>
                    <div className='navbar-collapse-content'>
                        <ul className='navbar-nav'>
                            <li className='text-white'>
                                <Link to = "/OverOns">Over Ons</Link>
                            </li>
                            <li className='text-white'>
                                <Link to = "/Projecten">Projecten</Link>
                            </li>

                            <li className='text-white'>
                                <Link to = "/Contact">Contact</Link>
                            </li>
                        </ul>
                        {/*<ul className='navbar-social flex'>*/}
                        {/*    <li className='text-white'>*/}
                        {/*        <Link to = "" className='flex flex-center'><FaTwitter /></Link>*/}
                        {/*    </li>*/}
                        {/*    <li className='text-white'>*/}
                        {/*        <Link to = "" className='flex flex-center'><FaSkype /></Link>*/}
                        {/*    </li>*/}
                        {/*    <li className='text-white'>*/}
                        {/*        <Link to = "" className='flex flex-center'><FaVimeoV /></Link>*/}
                        {/*    </li>*/}
                        {/*</ul>*/}


                        <div className='navbar-btns'>
                            <Link to = "/Vacature">
                                <button type = "button" className='btn'><IoMdRocket /> <span>VACATURE</span></button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
  )
}

export default Navbar
