import React from "react";
import sections from "../../constants/data";
import "./Vacature.css"

const Vacature = () => {
  return (
    <section className='portfolio section-p bg-dark' id = "portfolio">
        <div className='container'>
            <div className='portfolio-content'>
                <div className='section-t text-center'>
                    <h3>Vacature</h3>
                    <br/>
                    <h6 className='text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad assumenda at blanditiis deleniti dignissimos earum eligendi, inventore, iusto laudantium numquam optio porro quae quas quia quidem reiciendis repellendus sit tempore ut vitae! Aliquam architecto at autem eveniet nemo nulla obcaecati repudiandae voluptate? Animi, corporis rerum. Earum laboriosam nostrum odio tempora.</h6>
                </div>

                <div className='item-list text-center text-white grid'>
                    {
                        sections.portfolio.map(portfolio => {
                            return (
                                <div className='item flex flex-center flex-column translate-effect' key = {portfolio.id} style = {{
                                    background: `url(${portfolio.image})`
                                }}>
                                    <div className='item-title fs-25 fw-6'>{portfolio.title}</div>
                                    <div className='text text-white'>{portfolio.text}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </section>
  )
}

export default Vacature
