import React from 'react';
import LogoAltunIT from "../../assets/Logo-AltunIT-White.png";
import "./Footer.css";

const Footer = () => {

    let date = new Date();

  return (
    <footer className='footer bg-black flex flex-center' id = "footer">

        <div className='footer-content'>
            {/*<div className='footer-text'>*/}
            {/*    <span>De Specialist in slimme internet oplossingen</span>*/}
            {/*</div>*/}
            <div className='footer-copyright'>
                <span className='footer-text'>&copy; {new Date().getFullYear()}</span>
                <a href="https://www.AltunIT.nl" target="_blank" rel="noopener noreferrer" className="logo-link">
                    <img src={LogoAltunIT} alt="AltunIT Logo" className="footer-logo" />
                </a>
                <span className='footer-text'>All rights reserved</span>
            </div>
        </div>
    </footer>
  )
}

export default Footer
